<template>
	<section class="container">
		<Card 
			class="container-card" 
			background="block"
		>
			<div class="container-parabens">
				<img src="@ebradi/assets/img/image-4.svg">
				<h2>Parabéns, {{ name }}</h2>
				<p>
					Você concluiu o processo de compra do seu curso, agora você pode seguir direto 
					para o <a :href="portal_estudante" target="_blank">Portal do Estudante</a> criando sua senha 
					clicando no botão abaixo.
					<br/><br/>
					Caso prefira, pode retornar esse processo posteriormente,
					não deixe de conferir seu e-mail com as instruções!
				</p>
				<div class="is-flex pb-5">
					<b-button class="m-auto is-secondary" rounded>
						Vamos lá!
					</b-button>
				</div>
			</div>
		</Card>
	</section>
</template>

<script>
import Card from '@ebradi/componets/Card'

export default {
	name: "ParabensPageEBRADI",
	components: {
		Card
	},
	data() {
		return {
			name: 'Jaime!',
			portal_estudante: '#'
		}
	}
}
</script>

<style lang="scss" scoped>
.container {
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 82px;
}

.container-card {
	width: 100%;
	max-width: 775px;
}

.container-parabens {
	padding: 20px 92px;
	text-align: center;

	img {
		margin: 42px 0;
	}

	h2 {
		font-size: 28px;
		line-height: 32px;
		font-weight: 900;
	}

	p {
		margin: 42px 0;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	}

}
</style>
