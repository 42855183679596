<template>
	<div 
		class="ebradi"
		:class="`${size}`"
	>
		<div
			class="ebradi-card"
			:class="`${background} ${color}`"
		>
			<slot
				name="header"
			>
				<div
					v-if="header.length > 0"
					class="ebradi-card-header"
				>
					{{ header }}
				</div>
			</slot>
			<div class="ebradi-card-body">
				<slot />
			</div>
			<slot name="footer" />
		</div>
		<div
			v-if="bar"
			class="bar bar-center"
			:class="`${color}`"
		/>
		<div
			v-if="bars"
			class="bar bar-left"
			:class="`${color}`"
		/>
		<div
			v-if="bars"
			class="bar bar-right"
			:class="`${color}`"
		/>
	</div>
</template>

<script>
export default {
	name: 'CardComponetEBRADI',
	props: {
		header: {
			type: String,
			required: false,
			default: ''
		},
		bar: {
			type: Boolean,
			required: false,
			default: false
		},
		bars: {
			type: Boolean,
			required: false,
			default: false
		},
		color: {
			type: String,
			required: false,
			default: 'primary',
			validator: function (value) {
				return ['secondary', 'primary'].includes(value)
			}
		},
		size: {
			type: String,
			required: false,
			default: 'large',
			validator: function (value) {
				return ['normal', 'large'].includes(value)
			}
		},
		background: {
			type: String,
			required: false,
			default: 'transparent',
			validator: function (value) {
				return ['transparent', 'block', 'blur'].includes(value)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.ebradi {
	position: relative;

	&-card {
		position: relative;
		z-index: 2;
		border-radius: 32px;
		gap: 24px;

		&.primary {
			border-left: 2px solid var(--primary);

			.ebradi-card-header {
				border-bottom: 1px solid var(--primary);
			}
		}

		&.secondary {
			border-left: 2px solid var(--secondary-400);

			.ebradi-card-header {
				border-bottom: 1px solid var(--secondary-400);
			}
		}

		&.blur {
			background: rgba(#D9D9D90A, 0.04);
			backdrop-filter: blur(10px);
			-webkit-backdrop-filter: blur(10px);
		}

		&.transparent {
			background: transparent;
		}

		&.block {
			background: var(--charcoal-gray);
		}

		&-header {
			line-height: 24px;
			font-weight: 700;
			margin-bottom: 34px;
			padding-bottom: 34px;
			padding-top: 16px;
			margin-left: 24px;
			margin-right: 24px;
		}
	}

	.bar {
		border-radius: 12px;
		position: absolute;

		&.primary {
			background: var(--primary);
		}

		&.secondary {
			background: var(--secondary-400);
		}
	}

	&.large {
		.bar {
			&-center {
				width: 122px;
				height: 26px;
				top: -14px;
				left: calc(50% - 61px);
			}
			&-left {
				width: 121px;
				height: 26px;
				left: -14px;
				top: -14px;
			}
			&-right {
				bottom: -14px;
				right: -14px;
				width: 141px;
				height: 29px;
			}
		}
	}

	&.normal {
		.bar {
			&-center {
				width: 64px;
				height: 17px;
				top: -8px;
				left: calc(50% - 32px);
			}
	
			&-left {
				width: 64px;
				height: 20px;
				left: -14px;
				top: -8px;
			}
	
			&-right {
				width: 64px;
				bottom: -8px;
				right: -14px;
				height: 20px;
			}
		}
	}
}
</style>
